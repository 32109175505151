#countdown {
  position: relative;
  height: 40px;
  width: 40px;
  text-align: center;
}

#countdown-number {
  display: inline-block;
  line-height: 40px;
}

#countdown svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

#countdown svg circle {
  stroke-dasharray: 100px;
  stroke-dashoffset: 13px;
  stroke-linecap: round;
  stroke-width: 4px;
  fill: none;
}

.card-container {
  display: flex;
  justify-content: space-around;
}

.space {
  width: 30px;
}
