.new-message-form label {
  display: block;
  margin-bottom: 0.2rem;
  padding: 0;
}

.new-message-form input,
.new-message-form textarea,
.new-message-form .message-editor {
  padding: 0.4rem;
  margin: 0;
  width: 465px;
  border-radius: 4px;
  border-width: 1px;
}

.new-message-form textarea {
  height: 20rem;
  font-size: 14px;
  padding-right: 1rem;
}

.new-message-form .message-editor {
  height: 20rem;
  border: 1px solid rgb(118, 118, 118);
  margin-bottom: 0.3rem;
  font-size: 12px;
  line-height: 1.5;
  position: relative;
}

.new-message-form .messge-textarea {
  position: relative;
}

.message-editor .message-input {
  max-height: 100%;
  overflow: scroll;
  padding-right: 10px;
}

.message-input-questionmark {
  position: absolute;
  top: 1px;
  right: 3px;
  z-index: 10;
}

.message-input-info {
  position: absolute;
  white-space: pre;
  top: 23px;
  right: 3px;
  text-align: center;
  color: white;
  background-color: #717d8f;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  display: none;
  max-width: 95%;
  overflow: auto;
}

.message-input-questionmark:hover + .message-input-info {
  display: block;
}

.mce-content-body {
  margin: 0;
}

.new-message-form .submit-btn {
  border: 1.2px solid #546174;
  padding: 0.3rem;
  cursor: pointer;
  margin-top: 0.8rem;
  width: auto;
  color: white;
  background-color: #6c757d;
  display: inline-block;
  margin-right: 0.5rem;
  border-radius: 4px;
}

.new-message-form .submit-btn:hover {
  background-color: #343a40;
}

.new-message-form {
  margin-bottom: 2rem;
  font-size: 12px;
  color: #546174;
  border: 1px solid #ced4da;
  border-radius: 0 0 4px 4px;
  padding: 20px;
  width: fit-content;
}

.new-message-form .btns {
  display: flex;
  justify-content: flex-end;
}

.edit-message-hidden {
  display: none;
}

.new-message-nav {
  width: calc(465px + 42px);
  height: 30px;
  font-size: 12px;
  background-color: #717d8f;
  color: white;
  border-radius: 4px 4px 0 0;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.new-message-nav p {
  margin: 0;
  margin-right: 5px;
}

.empty-message-popup {
  position: fixed;
  top: calc(50vh - 60px);
  left: calc(50vw - 120px);
  border: 1px solid #546174;
  padding: 1rem 1.2rem;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  background-color: #546174;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-message-popup .title {
  text-align: center;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.empty-message-popup p {
  margin: 0;
}

.empty-message-popup .okbtn {
  padding: 0.3rem 0.4rem;
  cursor: pointer;
  background-color: white;
  border: 1.2px solid white;
  color: #546174;
  border-radius: 4px;
  margin-top: 0.8rem;
}

.empty-message-popup .okbtn:hover {
  background-color: #546174;
  color: white;
}

.empty-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background-color: #546174;
  opacity: 0.3;
}

@media (max-width: 1114px) {
  .new-message-form input,
  .new-message-form textarea,
  .new-message-form .message-editor,
  .new-message-form,
  .new-message-nav {
    width: 100%;
  }
}
