.google-signin {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(81, 99, 115);
}

.google-signin-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.google-signin__btn {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.google-signin__logo {
  max-width: 500px;
  height: auto;
}

.google-signin-content p,
.google-login__links,
.google-login__links a {
  color: rgb(204, 204, 204);
  font-size: 11px;
  margin: 0 0 10px 0;
}

.google-login__link {
  color: rgb(238, 238, 238);
  font-size: 0.9rem;
  margin-top: 15px;
}

.google-login__links a:hover {
  color: rgb(204, 204, 204);
  text-decoration: none;
}

.google-login__link:hover {
  color: rgb(238, 238, 238);
  text-decoration: none;
}

.google-login-unauthorized {
  position: absolute;
  top: 40px;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background-color: rgb(81, 99, 115);
  color: white;
  font-size: 16px;
}

.google-signin__btn .btns {
  display: flex;
  gap: 20px;
}

.google-signin__btn .btns .add-new-account-btn {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding: 10px;
  text-decoration: none;
}

.google-signin__btn .btns .add-new-account-btn:hover {
  opacity: 0.9;
}

.google-signin__btn .btns .add-new-account-btn svg {
  margin-right: 20px;
}

@media (max-height: 570px) {
  .google-login-unauthorized {
    flex-direction: row;
    gap: 10px;
  }
}

@media (max-height: 400px) {
  .google-login-unauthorized {
    top: 10px;
  }
}

@media (max-width: 500px) {
  .google-signin__btn .btns {
    flex-direction: column;
  }
}
