.message-setting {
  width: 485px;
  border: 1px solid #546174;
  margin-bottom: 1rem;
  padding: 1rem;
  color: #546174;
  font-size: 12px;
  border-radius: 4px;
  position: relative;
}

.message-setting:last-child {
  margin-bottom: 0;
}

.message-setting p {
  margin: 0;
  white-space: pre-line;
}

.message-seting__loading {
  color: #546174;
  font-size: 12px;
}

.message-setting-btns {
  position: absolute;
  top: 40px;
  right: 6px;
  z-index: 10;
}

.message-setting .dropdown-icon {
  position: absolute;
  top: 16px;
  right: 6px;
  width: 20px;
  height: 20px;
  padding-top: 1px;
  text-align: center;
  border-radius: 50%;
}

.message-setting .dropdown-icon:hover {
  background-color: #d7dbdd;
}

.setting-btn {
  padding: 0.4rem;
  border: 1px solid black;
  margin-right: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  width: 50px;
  text-align: center;
  margin-bottom: 5px;
  background-color: white;
}

.message-setting-btns .current-setting,
.message-setting-btns .setting-btn__edit:hover {
  color: white;
  background-color: #546174;
}

.message-setting__date,
.message-setting__message {
  display: block;
  margin-bottom: 0.5rem !important;
  overflow: auto;
}

.message-setting-border {
  border: 1px solid #ced4da;
  border-radius: 0 0 4px 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message-setting-nav {
  width: 100%;
  height: 30px;
  font-size: 12px;
  background-color: #717d8f;
  color: white;
  border-radius: 4px 4px 0 0;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.message-setting-nav p {
  margin: 0;
  margin-right: 5px;
}

.message-seting__loading {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #546174;
  font-size: 12px;
}

.setting-btn-next {
  width: fit-content;
  padding: 0.4rem;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 4px;
  align-self: flex-end;
  margin-top: 20px;
  font-size: 12px;
  margin-right: 0;
  color: white;
  background-color: #6c757d;
}

.setting-btn-next:hover {
  background-color: #343a40;
}

.no-more-messages {
  cursor: not-allowed;
}

.no-more-messages:hover {
  background-color: #6c757d;
}

.player-wrapper {
  width: 450px;
  height: calc(450px / 16 * 9);
  border-radius: 4px;
}

.image-wrapper img {
  height: auto;
  width: 450px;
  max-width: 100%;
  border-radius: 4px;
}

.message-setting a {
  display: block;
  max-width: 100%;
  overflow: scroll;
}

.status-container {
  display: flex;
}

.status-container .status {
  align-self: center;
  justify-self: center;
  padding: 0 5px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.status-container .status.shown {
  border: 1px solid #28a745;
  background-color: #28a74642;
}

.status-container .status.hidden {
  border: 1px solid #fd7e14;
  background-color: #fd7d1448;
}

.delete-message-popup {
  position: fixed;
  top: calc(50vh - 60px);
  left: calc(50vw - 120px);
  border: 1px solid #546174;
  padding: 1rem 1.2rem;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  background-color: #546174;
  z-index: 100;
}

.delete-message-popup .title {
  text-align: center;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.delete-message-popup .yesno-btns {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 0.8rem;
}

.delete-message-popup .yesnobtn {
  padding: 0.3rem 0.8rem;
  cursor: pointer;
  background-color: white;
  border: 1.2px solid white;
  color: #546174;
  border-radius: 4px;
}

.delete-message-popup .yesnobtn:hover {
  background-color: #546174;
  color: white;
}

.delete-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  background-color: #546174;
  opacity: 0.3;
}

@media (max-width: 560px) {
  .player-wrapper {
    width: 100%;
    height: calc(80vw / 16 * 9);
  }
}

@media (max-width: 460px) {
  .player-wrapper {
    height: calc(76vw / 16 * 9);
  }
}

@media (max-width: 1114px) {
  .message-setting {
    width: 100%;
  }
}

@media (min-width: 1115px) {
  .message-seting__loading {
    width: 530px;
  }
}
