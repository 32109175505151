.number {
  width: min(50px, 10vw) !important;
  height: min(55px, 12vw);
  border: #90a4c0 solid 1px;
  border-radius: 2px;
}

.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number[type="number"] {
  -moz-appearance: textfield;
}

.otp-input {
  display: flex;
  justify-content:center;
}