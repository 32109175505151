.pagination {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #546174;
}

.pagination .next,
.pagination .previous,
.pagination a,
.pagination .break {
    text-decoration: none;
    padding: 5px 12px;
    color: #546174;
    cursor: pointer;
}

.pagination .page-items {
    margin: 0px 15px;
    width: 30px;
    text-align: center;
}
.pagination .page-items-activity-list-table {
    word-wrap: initial;
}

.pagination .active a {
    background: #546174;
    color: #fafafa !important;
    border-radius: 10px;
}

.pagination .disabled {
    display: none;
}