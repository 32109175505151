div.reacttags .reacttags_tags {
  display: inline-flex;
  align-items: center; 
  justify-content: space-between;
  color: #fafafa;
  margin: 4px;
  font-size: 13px;
  padding: 2px 9px;
  border-radius: 11px;
}

div.reacttags .reacttags_tags_area {
  margin: 4px 0px;
  text-align: left;
  overflow-y: auto;
  max-height: 100px;
}

div.reacttags .reacttags_tags_remove{
  cursor: pointer;
  font-size: 11px;
  margin-left: 8px;
  align-self: center;
}

div.reacttags_tags_valid {
  background: #00C851
}

div.reacttags_tags_invalid {
  background: #FF4444
}

div.reacttags_tags_pending{
  background: #7d889a
}

input.reacttags_input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  width: 100%;
  height: 38px;
  font-size: 14px;
  margin-top: 8px;
  padding-left: 8px;
}