@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?17270047');
  src: url('../font/fontello.eot?17270047#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?17270047') format('woff2'),
       url('../font/fontello.woff?17270047') format('woff'),
       url('../font/fontello.ttf?17270047') format('truetype'),
       url('../font/fontello.svg?17270047#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?17270047#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-info:before { content: '\e800'; } /* '' */
.icon-star:before { content: '\e801'; } /* '' */
.icon-member-card:before { content: '\e802'; } /* '' */
.icon-user-add:before { content: '\e803'; } /* '' */
.icon-coupon:before { content: '\e804'; } /* '' */
.icon-home:before { content: '\e805'; } /* '' */
.icon-giftbox:before { content: '\e806'; } /* '' */
.icon-park:before { content: '\e807'; } /* '' */
.icon-arrow-send:before { content: '\e808'; } /* '' */
.icon-not_found:before { content: '\e809'; } /* '' */
.icon-box:before { content: '\e80a'; } /* '' */
.icon-dot-3:before { content: '\e80b'; } /* '' */
.icon-user_friends:before { content: '\e80c'; } /* '' */
.icon-globe:before { content: '\e80d'; } /* '' */
.icon-qrcode-card:before { content: '\e80e'; } /* '' */
.icon-diamond:before { content: '\e80f'; } /* '' */
.icon-member_info:before { content: '\e810'; } /* '' */
.icon-location:before { content: '\e811'; } /* '' */
.icon-light-phone:before { content: '\e812'; } /* '' */
.icon-reply:before { content: '\f112'; } /* '' */
