code.code-broadcast{ 
  font-family: 'Prompt', sans-serif !important;

  padding: 2px 4px;
  
  color: #e83e8c;    
  border: 1px solid #e83e8c;
  border-radius: 6px;
  font-weight: 600;
  
  line-height: 2.5;
}

code.disable{ 
  cursor: not-allowed;
  
  color: #54617488;    
  background: #eaecef;
  border: 1px solid #eaecef;
}

.custom-date-input.form-control[readonly] {
  background-color: #fff;
}

.custom-date-picker {
  font-family: 'Prompt', sans-serif !important;
  color: #546174;
  font-size: 12px;
}

.custom-date-picker.react-datepicker__day--selected {
  color: #fff;
  background-color: #0082ad;
}

.custom-date-picker.react-datepicker__day--disabled {
  color: #e4ebf1;
}

.custom-date-picker.react-datepicker__day--keyboard-selected {
  background-color: #43b7dd;
  color: #fff;
}

.custom-date-picker.react-datepicker__day--keyboard-selected:hover {
  background-color: #0082ad;
  color: #fff;
}

.custom-popper .react-datepicker__input-time-container {
  font-family: 'Prompt', sans-serif !important;
  color: #546174;
  font-size: 12px;
}

.custom-popper .react-datepicker-time__input {
  color: #546174;
  padding: 0px 2px;
}

.custom-popper .react-datepicker__header--custom {
  font-family: 'Prompt', sans-serif !important;
  background-color: rgba(0,0,0,.03);
}