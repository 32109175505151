.broadcast-container {
  padding: 20px;
  max-width: 100vw;
  overflow: hidden;
}

@media (min-width: 1115px) {
  .broadcast-container {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    max-width: 1300px;
    margin: 0 auto;
  }
}
