.member-switch {
    display: inline-block;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 999px;
    overflow: hidden;
    cursor: pointer;
    transition: border-color .3s, background-color .3s;
    color: #fafafa;
    width: 46px;
    height: 25px;
}

.member-switch.loading.on, .member-switch.loading.off {
    cursor: not-allowed;
    background: rgb(191, 203, 217);
}

.member-switch.on {
    background: green;
}

.member-switch.on::before {
    margin: 1px;
    top: 0px;
    left: 0px;
    content: '';
    display: block;
    position: absolute;
    overflow: hidden;
    background: #fafafa;
    transform: translate(100%, 0px);
    transition: transform .3s;
    border-radius: 100%;
    width: 21px;
    height: calc(100% - 2px);
}

.member-switch.off {
    background: rgb(191, 203, 217);
}

.member-switch.off::before {
    margin: 1px;
    top: 0px;
    left: 0px;
    content: '';
    display: block;
    position: absolute;
    overflow: hidden;
    background: #fafafa;
    transform: translate(0px, 0px);
    transition: transform .3s;
    border-radius: 100%;
    width: 21px;
    height: calc(100% - 2px);
}

.member-switch .switch-btn {
    background: transparent;
    padding: 10px;
    font-size: 14px;
}