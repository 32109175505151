@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.Toastify__toast {
  font-family: 'Prompt', sans-serif !important;
}


.main-bg {
  min-height: 100vh;
  background-image: url('./assets/img/bg.jpeg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-fluid {
  width: 100%;
}

.main-bg:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  min-height: 100vh;
  z-index: 0;
  background: inherit;
  filter: blur(5px);
}