.broadcast-navbar {
  height: 50px;
  width: 100%;
  background-color: #546174;
}

.broadcast-navbar p {
  margin: 0;
  color: white;
  font-size: 14px;
}

.broadcast-navbar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.broadcast-navbar-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 1094px;
  margin: 0 auto;
}

.broadcast-navbar-dropdown {
  display: flex;
  column-gap: 20px;
  align-items: center;
  position: relative;
}

.broadcast-navbar-dropdown p {
  cursor: pointer;
}

.broadcast-navbar-dropdown ul {
  padding: 5px 20px 0 20px;
  margin: 0;
  top: 40px;
  position: absolute;
  right: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #546174;
  list-style: none;
  border-radius: 0 0 4px 4px;
}

.broadcast-navbar-dropdown ul li {
  margin-bottom: 10px;
}

.broadcast-navbar .dropdown-hidden {
  display: none;
}

.broadcast-navbar .logout-btn {
  padding: 0.3rem 0.4rem;
  cursor: pointer;
  background-color: white;
  border: 1.2px solid white;
  color: #546174;
  border-radius: 4px;
}

.broadcast-navbar .logout-btn:hover {
  background-color: #546174;
  color: white;
}

.logout-confirmaion {
  position: fixed;
  top: calc(50vh - 60px);
  left: calc(50vw - 120px);
  border: 1px solid #546174;
  padding: 1rem 1.2rem;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  background-color: #546174;
  z-index: 100;
}

.logout-confirmaion .title {
  text-align: center;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.logout-confirmaion .logout-btns {
  display: flex;
  justify-content: space-around;
  margin-top: 0.8rem;
}

.logout-confirmaion .logoutbtn {
  padding: 0.3rem 0.4rem;
  cursor: pointer;
  background-color: white;
  border: 1.2px solid white;
  color: #546174;
  border-radius: 4px;
}

.logout-confirmaion .logoutbtn:hover {
  background-color: #546174;
  color: white;
}

.logout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  background-color: #546174;
  opacity: 0.3;
}

@media (max-width: 1114px) {
  .broadcast-navbar-container {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .broadcast-navbar-dropdown p {
    display: none;
  }
}
